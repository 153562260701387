import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore"
import { global, axios } from '../constants.js';

// Configuración de Firebase con las credenciales de la aplicación
import firebaseConfig from "../credentials.json"

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

console.log(firebaseConfig);

// Constantes
const typeObjectsStripe = ["customer", "subscription", "transaction", "charge", "plan", "invoice", "product", "price", "users_data", "free_subscription"];

// URL
const URL = 'https://cluegarden.com/login';

const auth = firebase.auth();
const db = firebase.firestore();

export const signInHandler = (email, password) => {
  return auth.signInWithEmailAndPassword(email, password)
    .then(async result => {
      if (result.user) {
        return result;
      }
      await auth.signOut();
      return null;
    })
    .catch(error => {
      console.error(error)
      return Promise.reject(error);
    })
}

export const loginFacebook = () => {
  return new Promise((resolve, reject) => {
    const provider = new firebase.auth.FacebookAuthProvider();
    firebase.auth().signInWithPopup(provider) // Sign in Modal
      .then((result) => {
        db.collection("users_data").doc(result.user.uid).set(
          {},
          { merge: true }
        )
          .then(async () => {
            console.log(result.user)
            console.log(result.user.sendEmailVerification)
            if (!result.user.emailVerified) {
              await result.user.sendEmailVerification({ url: URL });
            }
            resolve(result.user)
          })
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      })
  })
}

export const loginGoogle = () => {
  return new Promise((resolve, reject) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider) // Sign in Modal
      .then((result) => {
        db.collection("users_data").doc(result.user.uid).set(
          {},
          { merge: true }
        )
          .then(() => resolve(result.user))
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      })
  })
}

export const signUpHandler = (email, password, data) => {
  return auth.createUserWithEmailAndPassword(email, password)
    .then(result => {
      console.log(result);
      let date = new Date();
      let trial_time = 21;
      return Promise.all([result, 
        db.collection("users_data").doc(result.user.uid).set({
        ...data,
        customer_id: null,
        trial_completed_flag: false,
        trial_start_date: new Date(),
        trial_exp_date: new Date(date.setDate(date.getDate() + trial_time)),
        subscription_id: "sub_free"
      }, { merge: true }),
      db.collection("users").doc(result.user.uid).set({
        ...data,
        level: 1,
        journalEntriesCount: 0,
        progress: {},
        settings: { notifications: true, hour: 10 }
      }, { merge: true })])
    })
    .then(async ([result, _]) => {
      await result.user.sendEmailVerification({ url: URL });
      await auth.signOut();
      return true;
    })
    .catch(error => {
      console.error(error);
      return Promise.reject(error);
    })
}

/**
* Se envía al email suministrado un correo electronico con un enlace
* para resetear la contraseña del usuario
* @param {string} email email del usuario
*/
export const resetUserPassword = (email) => {
  return auth.sendPasswordResetEmail(email)
    .then(() => {
      // Email sent.
      return true;
    }).catch((error) => {
      // An error happened.
      console.log(error);
      return Promise.reject(error);
    });
}

/**
* Manejador generalizado para listar los objetos creados en Stripe
* @param {string} type colección a listar
*/
export const listStripeAllRecordsHandler = (type) => {
  return new Promise((resolve, reject) => {
    if (!type) {
      reject("Necesita especificarse un tipo o data");
    } else if (!typeObjectsStripe.includes(type)) {
      reject(`El tipo de objeto ${type} no está habilitado para ser creado en FireStore`);
    }
    db.collection(`${type}s`).get()
      .then(response => resolve(response))
      .catch(error => {
        console.log(error);
        reject(error);
      })
  })
}

/**
* Manejador generalizado para obtener la informacion de un objeto específico
* creado en Stripe
* @param {string} type colección a listar
* @param {string} id id de Firestore del objeto
*/
export const getStripeRecordHandler = (type, id) => {
  return new Promise((resolve, reject) => {
    if (!type) {
      reject("Necesita especificarse un tipo o data");
    } else if (!typeObjectsStripe.includes(type)) {
      reject(`El tipo de objeto ${type} no está habilitado para ser creado en FireStore`);
    }
    db.collection(`${type}s`).doc(id).get()
      .then(response => {
        console.log(response)
        if (response[0]) resolve(response[0])
        else resolve({})
      })
      .catch(error => {
        console.log(error);
        reject(error);
      })
  })
}

/**
* @param {string} type colección a listar
* @param {string} id id de Firestore del objeto
*/
export const getUserByUID = (uid) => {
  return new Promise((resolve, reject) => {
    getAuthUserByUIDServer(uid)
      .then((user) => {
        return Promise.all([user, db.collection('users_data').doc(uid).get()])
      })
      .then(([user, userData]) => {
        let data = {};
        if (userData) data = { ...userData.data() };
        data = { ...data, ...user };
        resolve(data);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      })
  })
}

/**
* @param {string} type colección a listar
* @param {string} id id de Firestore del objeto
*/
export const getUsers = () => {
  return new Promise((resolve, reject) => {
    getAuthUsersServer()
      .then((users) => {
        return Promise.all([users, db.collection('users_data').get()])
      })
      .then(([users, usersData]) => {
        let usersDataByID = {};
        usersData.forEach((user) => usersDataByID[user.id] = user.data());
        let usersInfo = users.map((user) => {
          if (usersDataByID[user.uid]) {
            return { ...usersDataByID[user.uid], ...user };
          } else {
            return user;
          }
        })
        usersInfo.sort((a, b) => {
          return new Date(b.metadata.creationTime) - new Date(a.metadata.creationTime);
        });
        resolve(usersInfo);
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      })
  })
}

/**
*/
export const activateFreePlan = (uid, email) => {
  return new Promise((resolve, reject) => {
    let date = new Date();
    let freePlanData = {
      trial_start_date: new Date(),
      trial_exp_date: new Date(date.setDate(date.getDate() + 1)),
      amount: 0,
      price_id: "price_free",
      subscription_id: "sub_free",
      nickname: "Intro Plan",
      description: "",
      duration: "month"
    }
    db.collection("users_data").doc(uid).get()
      .then((userResult) => {
        if (userResult) {
          let userData = { ...userResult.data() };
          if (userData.customer_id && (userData.trial_completed_flag === null || userData.trial_completed_flag === undefined)) {
            freePlanData.customer_id = userData.customer_id;
            db.collection("users_data").doc(uid).update({
              customer_id: userData.customer_id,
              subscription_id: freePlanData.subscription_id,
              trial_start_date: freePlanData.trial_start_date,
              trial_exp_date: freePlanData.trial_exp_date,
              trial_completed_flag: false,
            })
            .then( () => {
              return db.collection("free_subscriptions").add(freePlanData);
            })
            .then((freeSubscriptionResult) => {
              return resolve(freeSubscriptionResult);
            })
            .catch((error) => {
              console.log(error);
              return reject(error);
            })
          } else {
            return reject(`User ${email} has already completed the trial`);
          }
        }
      })
  })
}

export const deactivateFreePlan = (uid) => {
  return new Promise((resolve, reject) => {
    let freePlanData = {
      trial_exp_date: new Date(),
      trial_completed_flag: true,
      subscription_id: null,
    }
    db.collection("users_data").doc(uid).get()
      .then((userResult) => {
        if (userResult) {
          let userData = { ...userResult.data() };
          db.collection("users_data").doc(uid).update(freePlanData)
            .then( async () => {
              let resultFreePlan = await firebase.firestore().collection("free_subscriptions").where("customer_id", "==", userData.customer_id).get();
              if (!resultFreePlan.empty) {
                freePlanData.subscription_id = "sub_free";
                freePlanData.customer_id = userData.customer_id;
                delete freePlanData.subscription_id;
                return db.collection("free_subscriptions").doc(resultFreePlan.docs[0].id).update(freePlanData);
              } else {
                return;
              }
            })
            .then(() => {
              return resolve();
            })
            .catch((error) => {
              console.log(error);
              return reject(error);
            })
        }
      })
  })
}

//------------------------> User  ------------------------------->

export const getHeader = () => {
  return getToken().then((idToken) => {
    console.log(idToken, localStorage.getItem('userToken'))
    if (localStorage.getItem('userToken') && idToken !== localStorage.getItem('userToken')) {
      console.log("pasamos")
      localStorage.setItem("userToken", idToken)
    }
    return {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + idToken,
    };
  })
}

const getAuthUserByUIDServer = async (uid) => {
  const headers = await getHeader()
  let url = global.API_GET_AUTH_USER_UID.replace('__uid__', uid)
  return axios.get(url, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const getAuthUsersServer = async () => {
  const headers = await getHeader()
  let url = global.API_GET_AUTH_USERS;
  return axios.get(url, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const postCreateUserServer = async (data) => {
  const headers = await getHeader()
  let url = global.API_POST_CREATE_USER;
  return axios.post(url, data, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const updateUser = async (uid, data) => {
  const headers = await getHeader()
  let url = global.API_PATCH_UPDATE_USER.replace('__uid__', uid);
  return axios.patch(url, data, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const handlerDisabledUser = async (uid, data) => {
  const headers = await getHeader()
  let url = global.API_PATCH_DISABLE_USER.replace('__uid__', uid)
  return axios.patch(url, data, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const getToken = () => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((currentUser) => {
      if (currentUser) {
        resolve(currentUser.getIdToken());
      }
    });
  })
}

export { auth, firebase, db };