import React, { Component, Fragment } from 'react';

import Button from '@material-ui/core/Button';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';

// Material UI Components
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Helper
import { validateEmail } from '../../helpers/validateInputs';

// Services
import { updateUser, resetUserPassword, loginFacebook, loginGoogle, getToken } from '../../services/firebase';
import { verifyCustomerAndRetrieveUser } from '../../services/stripeServices';

// Components
import AlertForms from '../../components/alert_forms/AlertForms';
import ModalCG from '../../components/modal_cg/ModalCG';

// .CSS
import '../sign_up/SignUp.css'

import { countries } from '../../constants';

// Assets
import logow3 from "../../assets/logo-cg-icon-w3.png";
import logoText from "../../assets/logo-cg-w2.png";

const recaptchaRef = React.createRef();

class SignUp extends Component {

  constructor(props) {
    super(props);

    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      postalCode: '',
      birthdate: null,
      gender: null,
      genderError: null,
      country: null,
      countryError: null,
      firstNameError: null,
      emailError: null,
      passwordError: null,
      erroAletMesagge: null,
      postalCodedError: null,
      openModal: false,
      isVerified: false,
      loading: false,
      specialUpdates: true,
      termsConditions: false,
      resetPass: false,
      currentUser: null
    };
  }

  componentDidMount(){
      
    if (localStorage.getItem("userData")) {
        const data = JSON.parse(localStorage.getItem("userData"))
        if (data.birthdate && data.birthdate._seconds) {
            let date = this.formattDate(data.birthdate._seconds * 1000)    
            data.birthdate = date
        }else if (typeof data.birthdate === "string") {
            let date = this.formattDate(new Date(data.birthdate))    
            data.birthdate = date
        }
        
        this.setState({ ...data, currentUser: data })    
    }
  }

  formattDate = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth()+1; 
    const yyyy = date.getFullYear();
    if(dd<10) dd=`0${dd}` 
    if(mm<10) mm=`0${mm}`
    date = `${yyyy}-${mm}-${dd}`;
    return date
  }

  countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  }

  handleClickShowPassword = (pass) => {
    this.setState({ [pass]: !this.state[pass] })
  };

  onChange = (event) => {
    const name = event.target.name
    let value = (name === "specialUpdates" || name === "termsConditions") ? event.target.checked : event.target.value;
    this.setState({ [name]: value })
  }

  onChangeAutoComplete = (e, value) => {
    this.setState({ country: value })
  }

  onChangeAutoCompleteGender = (e, value) => {
    this.setState({ gender: value })
  }


  validateSignUpForm = () => {
    let {
      firstname,
      lastname,
      postalCode,
      country,
      gender,
    } = this.state;

    let firstNameError = null
    let lastNameError = null
    let erroAletMesagge = null
    let postalCodedError = null
    let countryError = null
    let genderError = null

    let error = false;

    if (firstname.trim() === '') {
      error = true
      firstNameError = 'Name is required'
    }

    if (lastname.trim() === '') {
      error = true
      lastNameError = 'Last name is required'
    }

    // if (!country) {
    //   error = true
    //   countryError = 'Country input is required'
    // }

    // if (!gender) {
    //   error = true
    //   genderError = 'Gender input is required'
    // }

    // if (postalCode.trim() === '') {
    //   error = true
    //   postalCodedError = 'Postal Code input is required'
    // }

    this.setState({
      firstNameError,
      lastNameError,
      erroAletMesagge,
      postalCodedError,
      countryError,
      genderError
    })

    if (!error) {
      let data = {};
      data.displayName = `${this.state.firstname}${this.state.lastname ? " " + this.state.lastname : ""}`;
      data.firstname = this.state.firstname;
      if (this.state.lastname) data.lastname = this.state.lastname;
      if (this.state.postalCode) data.postalCode = this.state.postalCode;
      if (this.state.gender) data.gender = this.state.gender;
      if (this.state.birthdate) data.birthdate = new Date(this.state.birthdate);
      if (this.state.country) data.country = this.state.country;
      data.specialUpdates = this.state.specialUpdates;
      data.termsConditions = this.state.termsConditions;
      this.setState({ loading: true })
    
      updateUser(this.state.uid, data)
      .then(() => {
          const dataToSend = { ...this.state.currentUser, ...data}
          localStorage.setItem('userData', JSON.stringify(dataToSend))
          this.setState({
              loading: false,
              successAletMesagge: 'User Updated successfully',
              openModal: true
          })
      })
      .catch((error) => {
          if (error.request && error.request.response) {
              const text = JSON.parse(error.request.response)
              this.setState({
                  loading: false,
                  erroAletMesagge: text.message
              })
          }else{
              this.setState({
                  loading: false,
                  erroAletMesagge: 'An error occurred'
              })
          }
      })

    }
  }

  
  redirectToLanding = () => {
    let from = { pathname: '/' }
    this.props.history.push(from);
  }

  goToLogin = () => {
    let from = { pathname: '/login' }
    this.props.history.push(from);
  }


  resetPass = () =>{
    if (this.state.email) {
        resetUserPassword(this.state.email)
        this.setState({
          successAletMesagge: 'Reset Password sent to the user`s email',
          openModal: true,
          resetPass: true
      })
    }
  }

  accepResetPass = () => {
    this.setState({
        openModal: false,
        resetPass: false
    })
  }

  render() {
    return (
      <div className="container-login-element color-form-cont cont-sign-up">
        <CssBaseline />
        <Container component="div" maxWidth="xs" className="center-login-element">
          <div className="cont-img-header-login">
            <img src={logow3} alt="logo" className="img-header-login-logo" />
            <img src={logoText} alt="textLogo" className="img-header-login-textLogo" />
          </div>
          <Typography component="h1" variant="h5" style={{
              color: 'white',
              marginTop: '20px'
            }}>
            Edit your account:
          </Typography>

          <div className="form-tag-login-container space-login-container" noValidate>
            {
              this.state.erroAletMesagge &&
              <div className="container-alert-validations">
                <AlertForms severity="error"> {this.state.erroAletMesagge} </AlertForms>
              </div>
            }

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  autoComplete="firstname"
                  value={this.state.firstname}
                  placeholder="Name"
                  onChange={(e) => this.onChange(e)}
                  className="inputs-for-users"
                />

                {this.state.firstNameError &&
                  <div className="show-eror-iput-user"> {this.state.firstNameError} </div>
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                <input
                  type="text"
                  id="lastname"
                  name="lastname"
                  autoComplete="lastname"
                  value={this.state.lastname}
                  placeholder="Last Name"
                  onChange={(e) => this.onChange(e)}
                  className="inputs-for-users"
                />

                {this.state.lastNameError &&
                  <div className="show-eror-iput-user"> {this.state.lastNameError} </div>
                }
              </Grid>
              <Grid item xs={12}>
                <input
                  type="text"
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={this.state.email}
                  placeholder="Email address"
                  onChange={(e) => this.onChange(e)}
                  className="inputs-for-users"
                  disabled={true}
                />

              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="gender"
                  options={['Man', 'Woman']}
                  disableClearable
                  disablePortal
                  getOptionLabel={(option) => option}
                  onChange={(e, value) => this.onChangeAutoCompleteGender(e, value)}
                  value={this.state.gender}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        {...params.inputProps}
                        type="text"
                        name="gender"
                        placeholder="Gender"
                        className="inputs-for-users"
                        autocomplete='disabled'
                      />
                    </div>
                  )}
                />

                {this.state.genderError &&
                  <div className="show-eror-iput-user"> {this.state.genderError} </div>
                }

              </Grid>
              <Grid item xs={12}>
                <input
                  type="date"
                  name="birthdate"
                  value={this.state.birthdate}
                  onChange={(e) => this.onChange(e)}
                  required
                  max="2014-12-31"
                  className="inputs-for-users"
                />
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  id="country"
                  options={countries}
                  disableClearable
                  disablePortal
                  getOptionLabel={(option) => option.label}
                  onChange={(e, value) => this.onChangeAutoComplete(e, value)}
                  value={this.state.country}
                  renderOption={(option) => (
                    <React.Fragment>
                      <span>{this.countryToFlag(option.code)}</span>
                      {option.label} ({option.code})
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <div ref={params.InputProps.ref}>
                      <input
                        {...params.inputProps}
                        type="text"
                        name="country"
                        placeholder="Country"
                        className="inputs-for-users"
                        autocomplete='disabled'
                      />
                    </div>
                  )}
                />

                {this.state.countryError &&
                  <div className="show-eror-iput-user"> {this.state.countryError} </div>
                }

              </Grid>
              <Grid item xs={12} style={{marginBottom: '20px'}}>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  autoComplete="postalCode"
                  value={this.state.postalCode}
                  placeholder="Zip Code"
                  onChange={(e) => this.onChange(e)}
                  className="inputs-for-users"
                />

                {this.state.postalCodedError &&
                  <div className="show-eror-iput-user"> {this.state.postalCodedError} </div>
                }
              </Grid>
            </Grid>

            <Grid item xs={12}>
                <Button
                        fullWidth
                        size="small"
                        variant="outlined"
                        color="inherit"
                        disabled={this.state.loading}
                        startIcon={<RotateLeftIcon />}
                        onClick={ this.resetPass }
                >
                    Reset Password
                </Button>
            </Grid>

            <div className="btn-options-login">
              <div className="btn-options-login-icons">
              </div>
              
              <div style={{ textAlign:"center", cursor: 'pointer' }} >
                <button
                className="cont-continue-btn"
                disabled={this.state.loading}
                onClick={this.validateSignUpForm}>
                  <span
                    className="btn-forms-c-g"
                  >
                    Continue
                  </span>
                </button>
                <span className="sign-up-all-fields" onClick={ this.redirectToLanding } > Go back </span>
              </div>
            </div>

          </div>
        </Container>

        <ModalCG
            openModal={this.state.openModal}
            type="succes"
            title={this.state.successAletMesagge}
            btnAccept={{
                    text: "Accept",
                    action: this.state.resetPass ? this.accepResetPass : this.redirectToLanding
                }}
        />
      </div>
    )
  }
}

export default SignUp;