import React, { useEffect } from 'react';
// Planes
import TemporalPlans from '../../components/temporal_plans/TemporalPlans';
import MenuUsers from '../../components/menu_users/MenuUsers';

const myHTML = `
<!-- header area start  -->
<header class="header-area header-bg-4 style-four" id="home">
      <div class="header-right-image wow zoomIn">
          <img src="assets/img/mobile-image-5.png" alt="ClueGarden Mobile App">
      </div>
      <div class="container">
          <div class="row">
              <div class="col-lg-7">
                  <div class="header-inner">
                      <p><strong>Welcome to</strong></p>

                      <h1><img src="assets/img/logo-cg-w2.png" alt="ClueGarden"></h1>

                      <p>365 days of self-discovery</p>
                      <div class="btn-wrapper wow fadeInUp">
                         <p><strong>Download now:</strong></p>
                          <a href="https://apps.apple.com/us/app/cluegarden/id1526613101" class="boxed-btn-02 reverse-color" target="_blank"><i class="flaticon-apple-1"></i> App Store</a>
                          <a href="javascript:void(0)" class="boxed-btn-02 blank" style="position:relative"><i class="flaticon-android-logo"></i> Play Store <span style="font-size: 8px; position: absolute; right: 30px; top: 17px;">Coming soon</span></a>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </header>
<!-- header area end  -->

<!-- What is area start -->
<section class="video-area" id="what">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="img">
                    <div class="img-wrap wow zoomIn">
                        <img src="assets/img/cg-flwr.png" alt="What is ClueGarden">
                    </div>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="right-content-area">
                    <h1 class="title">What is ClueGarden?</h1>
                    <p><strong>365 Days &nbsp; ● &nbsp; 19 Levels &nbsp; ● &nbsp; 7 Qualities</strong></p>
                    <p>ClueGarden is a self-discovery program designed to help you experience a more authentic life. Our research-based approach is unbiased and focuses on the intersection of science, spirituality, and society.</p>
                    <p>The ClueGarden expedition will challenge you for 365 days, take you through 19 levels, and help you develop 7 core qualities.</p>
                    <p>Covering a wide range of topics – from wisdom and curiosity through perseverance, hope, and gratitude, to nutrition and inner chemistry – ClueGarden will help you break the barriers that have held you back.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- What is area end -->

<!-- Why area start -->
<section class="video-area" id="why">
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="right-content-area">
                    <h1 class="title">Why ClueGarden?</h1>
                    <p>Life can be confusing. The things that we believe bring happiness often disappoint us. Deep inside, we wonder: “What am I missing?”</p>
                    <p>All the greatest thinkers in human history – philosophers, scientists, prophets, poets, artists, travelers, intellectuals – were at this same point once. Then they started searching...</p>
                    <p>With ClueGarden, you follow their traces and embark on your very own adventure of self-discovery. Be brave, be curious and trust the instinct that led you to this expedition, it will guide you to its goal!</p>
                </div>
            </div>
            <div class="col-lg-6">
                <div class="img">
                    <div class="img-wrap wow zoomIn">
                        <img src="assets/img/face-sm.png" alt="Why ClueGarden">
                    </div>
                </div>
              </div>
        </div>
    </div>
</section>
<!-- Why area end -->


<!-- How does it work area start -->
<section class="why-choose-area why-choose-us-bg" id="how">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="section-title white"><!-- section title -->
                    <h3 class="title extra">How does it work?</h3>
                </div><!-- //. section title -->
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-12">
                <div class="single-why-us-item margin-top-60 wow zoomIn"><!-- single why us item -->
                    <div class="content">
                        <h4 class="title">Choose a path that inspires you</h4>
                        <p>Select a daily challenge from one of seven topics.</p>
                    </div>
                </div>
                <div class="single-why-us-item wow zoomIn"><!-- single why us item -->
                    <div class="content">
                        <h4 class="title">Look at life through the eyes of great thinkers</h4>
                        <p>Inclusive, unbiased learning that you can apply to your own life.</p>
                        <br />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="center-image">
                    <div class="iphone-box">
                        <div class="iphone-box-inside">
                            <div class="screenshort-carousel"><!-- screenshort carousel -->
                                <div class="single-screenshort-item" ><!-- single screenshort item -->
                                    <img src="assets/img/phone/cg-001-new-1.png" alt="">
                                </div><!-- //.single screenshort item -->
                                <div class="single-screenshort-item" ><!-- single screenshort item -->
                                    <img src="assets/img/phone/cg-002-new-1.png" alt="">
                                </div><!-- //.single screenshort item -->
                                <div class="single-screenshort-item" ><!-- single screenshort item -->
                                    <img src="assets/img/phone/cg-003-new-1.png" alt="">
                                </div><!-- //.single screenshort item -->
                                <div class="single-screenshort-item" ><!-- single screenshort item -->
                                    <img src="assets/img/phone/cg-004-new-1.png" alt="">
                                </div><!-- //.single screenshort item -->
                            </div><!-- //. screenshort carousel -->
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="single-why-us-item margin-top-60 wow zoomIn"><!-- single why us item -->
                    <div class="content">
                        <h4 class="title">Accept your daily challenge</h4>
                        <p>Everyday exercises for everyone.</p>
                        <br />
                    </div>
                </div><!-- //. single why us item -->
                <div class="single-why-us-item wow zoomIn"><!-- single why us item -->
                    <div class="content">
                        <h4 class="title">Check your progress as you learn and grow</h4>
                        <p>Find clues and connect dots on your self-discovery adventure.</p>
                        <br />
                    </div>
                </div><!-- //. single why us item -->
            </div>
        </div>
    </div>
</section>
<!-- How does it work area end -->

<!-- screenshot area start -->
<section class="screenshort-area team-member-area" id="faqs">

    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="section-title"><!-- section title -->
                    <h3 class="title extra">FAQs</h3>
                    <p><Strong>Have any questions? Find your answer below.</Strong></p>
                </div><!-- //. section title -->
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="faqs-section">
                    <!-- FAQ -->
                    <div id="accordion" class="accordion">
                        <div class="card mb-0">
                            <div class="card-header collapsed" data-toggle="collapse" href="#collapseOne">
                                <a class="card-title"> How do I use the app? </a>
                            </div>
                            <div id="collapseOne" class="card-body collapse" data-parent="#accordion">
                                <p>Pick a daily challenge from the seven topics. You are free to select the order, and each challenge allows you to dig deeper into its meaning. Complete all challenges and move on to the next level!</p>
                                <p>Tip: improve your results by tying ClueGarden to a daily routine such as waking up, eating breakfast, or taking a break at work.</p>
                            </div>
                            <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseTwo">
                                <a class="card-title"> What's the benefit for me? </a>
                            </div>
                            <div id="collapseTwo" class="card-body collapse" data-parent="#accordion">
                                <p>If you think: "There's got to be more to life than this!", then you should try ClueGarden. Through everyday challenges and conquerable achievements, we help you explore unanswered questions.</p>
                            </div>
                            <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseThree">
                                <a class="card-title"> How can ClueGarden know the answers to my personal questions about life? </a>
                            </div>
                            <div id="collapseThree" class="collapse card-body " data-parent="#accordion">
                                <p>We don't, the answers at the end of the self-discovery quest are different for everyone. But the process of finding them is the same, and ClueGarden helps you explore the right places.</p>
                            </div>
                            <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFour">
                                <a class="card-title"> How is ClueGarden different from other self-discovery tools? </a>
                            </div>
                            <div id="collapseFour" class="collapse card-body " data-parent="#accordion">
                                <p>At ClueGarden, we use an unbiased approach and search for coinciding puzzle pieces from science, spirituality, and society. That way, you don't need to choose one source over another.<br /><br />We find that whenever contrasting sources say the same thing, there is truth. So we curate wisdom from across all time periods, cultures, and backgrounds, and we connect the dots.</p>
                            </div>
                            <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSix">
                                <a class="card-title"> That sounds great, but what if it's too hard for me? </a>
                            </div>
                            <div id="collapseSix" class="collapse card-body " data-parent="#accordion">
                                <p>ClueGarden is designed to be fun, easy, accessible, and spark self-reflection. It helps you find answers that are already within you. So it’s not about learning any new skills, it’s about getting rid of “inner clutter”. Think of it as cleaning up inside of you!<br /><br />Our program has a sense of trivia-like learning that everyone can apply to their daily life. So, just be curious and trust the instinct that led you here, it will show you the way!</p>
                            </div>
                            <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseFive">
                              <a class="card-title"> Who created ClueGarden? </a>
                            </div>
                            <div id="collapseFive" class="collapse card-body " data-parent="#accordion">
                              <p>ClueGarden is not about us, it’s about how it can improve your life. But if you’d like to know, we are a group of communication professionals.<br /><br />We spent years researching the topic and discovered that self-discovery is not as complicated as everyone thinks, it’s just being miscommunicated. Self-discovery is actually a highly biological process, it’s about changing your inner chemistry. In a way, it’s the same as going to the gym: you do it long enough, with a serious intention, and you will feel the positive changes over time.<br /><br />We created ClueGarden to demystify the topic and show how the quest for self-discovery can – and should – become a part of everyone’s daily life. We hope you enjoy it!</p>
                            </div>
                            <div class="card-header collapsed" data-toggle="collapse" data-parent="#accordion" href="#collapseSeven">
                                <a class="card-title"> How much is ClueGarden? </a>
                            </div>
                            <div id="collapseSeven" class="collapse card-body " data-parent="#accordion">
                                <p>The first 21 days are free. The rest of your journey requires either a monthly payment or a (discounted) one-time payment. Please see our <a href="#pricing">pricing options</a> section for details.</p>
                            </div>
                        </div>
                    </div>
                  <!-- FAQ -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- screenshot area end -->
`
const mySecondHTML = `
<!-- team member area start -->
<section class="team-member-area" id="team">
    <div class="bg-shape-2">
        <img src="assets/img/contact-bg.jpg" alt="">
    </div>
    <div class="bg-shape-3">
        <img src="assets/img/contact-icon-bg.png" alt="">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-6">
                <div class="contact-area-wrapper" id="contact"><!-- contact area wrapper -->
                    <h3 class="title">Get in touch</h3>
                    <p>If you have any questions or comments please share them below.</p>
                    <form action="index.html" id="contact_form_submit" class="contact-form sec-margin">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="uname" placeholder="Your Name">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="email" placeholder="Your Email">
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group textarea">
                                    <textarea name="message" id="message" class="form-control" cols="30" rows="10" placeholder="Message"></textarea>
                                </div>
                                <button class="submit-btn  btn-rounded gd-bg-1" type="submit">Submit Now</button>
                            </div>
                        </div>
                    </form>
                </div><!-- //. contact area wrapper -->
            </div>
        </div>
    </div>
</section>
<!-- team member area end -->

<!-- footer area start -->
<footer class="footer-area">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-5">
                    <div class="footer-widget about_widget">
                        <a href="index.html" class="footer-logo"><img src="assets/img/logo-white.png" alt=""></a>
                        <p>365 days of self-discovery.</p>
                        <ul class="social-icon">
                            <li><a href="https://www.facebook.com/cluegarden" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="https://www.instagram.com/cluegarden/" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="https://twitter.com/cluegarden" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/cluegarden" target="_blank"><i class="fab fa-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget nav_menus_widget">
                        <h4 class="widget-title">Useful Links</h4>
                        <ul>
                            <li><a href="#top"><i class="fas fa-chevron-right"></i> Home</a></li>
                            <li><a href="#what"><i class="fas fa-chevron-right"></i> What</a></li>
                            <li><a href="#why"><i class="fas fa-chevron-right"></i> Why</a></li>
                            <li><a href="#how"><i class="fas fa-chevron-right"></i> How</a></li>
                            <li><a href="#faqs"><i class="fas fa-chevron-right"></i> FAQs</a></li>
                            <li><a href="#pricing"><i class="fas fa-chevron-right"></i> Pricing</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6">
                    <div class="footer-widget nav_menus_widget">
                        <h4 class="widget-title">Need help?</h4>
                        <ul>
                            <li><a href="#contact"><i class="fas fa-chevron-right"></i> Support</a></li>
                            <li><a href="privacy"><i class="fas fa-chevron-right"></i> Privacy Policy</a></li>
                            <li><a href="terms"><i class="fas fa-chevron-right"></i> Terms &<br /> &nbsp; &nbsp; Conditions</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area"><!-- copyright area -->
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="copyright-inner"><!-- copyright inner wrapper -->
                        <div class="left-content-area"><!-- left content area -->
                            &copy; ClueGarden. All rights reserved.
                        </div><!-- //. left content aera -->
                    </div><!-- //.copyright inner wrapper -->
                </div>
            </div>
        </div>
    </div><!-- //. copyright area -->
</footer>
<!-- footer area end -->

  <!-- back to top area start -->
  <div class="back-to-top">
        <i class="fas fa-angle-up"></i>
  </div>

  <!-- back to top area end -->
`

  
export default function Landing(props) {

    useEffect(() => {
        setTimeout(()=> {
            window.initScreenshotCarousel();
            console.log("usePageViews()");
        }, 250);
    }, []);

  return (
    <div>
      <MenuUsers landing/>

      <div dangerouslySetInnerHTML={{ __html: myHTML }} />

      <TemporalPlans {...props} theme={props.theme} />

      <div dangerouslySetInnerHTML={{ __html: mySecondHTML }} />
    </div>

  );
}