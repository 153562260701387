
import React, { useContext, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
// import AppBar from '@material-ui/core/AppBar';
// import Button from '@material-ui/core/Button';
// import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
// import CardContent from '@material-ui/core/CardContent';
// import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
// import Grid from '@material-ui/core/Grid';
// import Toolbar from '@material-ui/core/Toolbar';
// import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
// import Container from '@material-ui/core/Container';
import Backdrop from '@material-ui/core/Backdrop';
import { CircularProgress } from "@material-ui/core";
import Modal from '@material-ui/core/Modal';

// Services
import { createCheckoutSessionHandler, createBillingPortalSession, verifyCustomerAndRetrieveUser } from '../../services/stripeServices';
import { listStripeAllRecordsHandler, auth, activateFreePlan, getUserByUID, deactivateFreePlan } from '../../services/firebase';

// Component
import ModalCG from '../modal_cg/ModalCG';
import UserForm from '../../containers/user_form/UserForm';

// Context
import { UserContext } from "../../providers/UserProvider";

// .CSS
import './TemporalPlans.css'

const urlParams = new URLSearchParams(window.location.search);
const sessionId = urlParams.get("session_id")

if (sessionId) {
  localStorage.setItem("checkout_session_id", sessionId)
}

// const showUserName = () => {
//   const userData = JSON.parse(localStorage.getItem('userToken'))
//   return userData.email
// }

// const showPlanName = () => {
//   const userData = JSON.parse(localStorage.getItem('userToken'))
//   let planName =
//     userData.subscription_data && userData.subscription_data.plan && userData.subscription_data.plan.nickname ? userData.subscription_data.plan.nickname :
//       userData.subscription_id === "sub_free" && userData.subscription_data && userData.subscription_data.nickname ? userData.subscription_data.nickname : "None"
//   return planName;
// }

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
}));

export default function Pricing() {

  const classes = useStyles();

  const signOut = (priceID) => {
    if (!localStorage.getItem('userToken')) {
      setRedirectToStripe(true);
      setLoginPriceID(priceID);
    } else {
      auth.signOut().then(function () {
        localStorage.clear();
        window.location.pathname = '/'
      }).catch(function (error) {
        alert('Algo salio mal')
      });
    }
  }

  const callData = () => {
    listStripeAllRecordsHandler('price')
      .then((result) => {
        let prices = result.docs.map(res => {
          return {
            ...res.data(),
            id: res.id
          }
        })

        // 

        listStripeAllRecordsHandler('product')
        .then((resultP) => {
          let products = resultP.docs.map(res => {
            return {
              ...res.data(),
              id: res.id
            }
          })

          prices = prices.filter(value => value.active)
          const dataToshow = prices.map(value => {
            const prodAsociate = products.find(prod => prod.product_id === value.product)
            value.description = prodAsociate.description.split('//')
            return value
          })
          setPrices(dataToshow)
        })
        .catch((error) => {
          console.log(error)
        })
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const verifyUser = (user) => {
    let userData = JSON.parse(localStorage.getItem('userData'));
    let uid = userData && userData.uid || user && user.uid;
    let email = userData && userData.email || user && user.email;
    verifyCustomerAndRetrieveUser(uid, email)
      .then((response) => {
        localStorage.setItem('userData', JSON.stringify(response.data))
        setUserData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
  }

  // Current User
  const { currentUser } = useContext(UserContext);
  const [redirectToStripe, setRedirectToStripe] = useState(null);
  const [loginPriceID, setLoginPriceID] = useState(null);
  const [userInformation, setUserInformation] = useState(null);
  const [modalForm, setModalForm] = useState(false);
  const [currentPrices, setPrices] = useState([]);
  const [currentUserData, setUserData] = useState(localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null);
  const [openModal, setOpenModal] = useState(null);
  const [openModalFreeTrial, setOpenModalFreeTrial] = useState(null);
  const [openFreeModal, setFreeOpenModal] = useState(null);
  const [backdrop, setHandlerBackdrop] = useState(false);

  useEffect(() => {
    callData()
    verifyUser(currentUser);
  }, [])

  const createBillingPortalSessionHandler = () => {
    let uid = currentUser.uid;
    createBillingPortalSession(uid)
      .then((url) => {
        window.location.href = url;
      })
      .catch((err) => {
        console.log(err);
      })
  }

  const openModalHandler = (flag) => {
    setOpenModal(flag)
  }
  const openFreeModalHandler = (flag) => {
    setFreeOpenModal(flag)
  }

  const activateFreePlanHandler = (uid, email) => {
    activateFreePlan(uid, email)
      .then(() => {
        verifyUser({ uid, email })
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const deactivateFreePlanHandler = (uid, email) => {
    deactivateFreePlan(uid)
      .then(() => {
        verifyUser({ uid, email })
        setOpenModalFreeTrial(false)
      })
      .catch((error) => {
        console.log(error);
      })
  }

  const freePlanData = {
    title: 'Intro Plan',
    subheader: 'Free Trial',
    price: '0',
    description: [],
  }

  const editUserForm = async () => {
    const userUid = JSON.parse(localStorage.getItem('userData')).uid
    try {
      const response = await getUserByUID(userUid)
      setUserInformation(response)
      setModalForm(true)
    } catch (error) {
      console.error('error in edtiUserForm', error);
    }
  }

  const cleanModalData = () => {
    setModalForm(false)
  }

  const closeModalAndUpdate = () => {
    setModalForm(false)
  }

  const onClickFreeTrialHandler = (freePriceID) => {
    if (!localStorage.getItem('userToken')) {
      signOut(freePriceID);
    } else {
      if (currentUserData && currentUserData.subscription_id) {
        setOpenModal(true);
      } else if ( currentUserData && currentUserData.trial_completed_flag) {
        setFreeOpenModal(true);
      } else if (currentUserData) {
        activateFreePlanHandler(currentUserData.uid, currentUserData.email);
      }
    }
  }
console.log("currentUserData",currentUserData);
  return (
    <React.Fragment>
      { redirectToStripe && 
        <Redirect 
          to={{
            pathname: "/login",
            state: { redirectToStripe, priceID: loginPriceID }
          }}
        />
      }
      <CssBaseline />
      <section className="pricing-plan-area pricing-plan-bg" id="pricing">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <div className="section-title white">
                <h3 className="title extra">No confusing pricing options</h3>
                <p><strong>You can experience ClueGarden in three ways:</strong></p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-4 plan-column">
              <div className="single-price-plan-02 wow zoomIn">
                <div className="price-header">
                  <h4 className="name">Intro Plan</h4>
                  <div className="price-wrap">
                    <span className="price">Free Trial</span>
                    <span className="month"></span>
                  </div>
                </div>
                <div class="price-body">
                        <ul>
                          <li>No credit card required</li>
                          <li>No automatic charge</li>
                          <li>21 days of discovery</li>
                        </ul>
                </div>
                <div className="price-footer">
                  {
                    currentUserData && !currentUserData.trial_completed_flag && currentUserData.subscription_data && currentUserData.subscription_id === "sub_free" ?
                      <span className="boxed-btn" onClick={() => setOpenModalFreeTrial(true)} > Current Plan </span>
                      :
                      <span id="btnTrial" 
                        disabled={ currentUserData && currentUserData.trial_completed_flag ? true : false}
                        onClick={() => onClickFreeTrialHandler("sub_free")}
                        className="boxed-btn"
                      >
                        {localStorage.getItem('userToken') ? 'Checkout' : 'Login'}
                      </span>
                  }
                </div>
              </div>
            </div>

            {currentPrices && currentPrices.map((tier) => (
              <div className="col-lg-4 col-md-4 plan-column">
                <div className="single-price-plan-02 wow zoomIn">
                  <div className="price-header">
                    <h4 className="name">{tier.nickname}</h4>
                    <div className="price-wrap">
                      <span className="price">${tier.unit_amount / 100}</span>
                      <span className="month"> {tier.recurring.interval == "month" ? tier.recurring.interval : ""} </span>
                    </div>
                  </div>
                  <div class="price-body">
                        <ul>
                          {
                            tier.description.map(value=> <li>{value}</li>)
                          }
                        </ul>
                  </div>
                  <div className="price-footer">
                    {
                      currentUserData && currentUserData.subscription_data && currentUserData.subscription_data.plan && currentUserData.subscription_data.plan.id === tier.price_id ?
                        <span className="boxed-btn" onClick={() => createBillingPortalSessionHandler()}> Current Plan </span>
                        :
                        <span
                          className="boxed-btn" id={'btn' + tier.nickname.replaceAll(' ', '')}
                          onClick={() =>
                            localStorage.getItem('userToken') ? 
                            currentUserData && currentUserData.subscription_id  && currentUserData.subscription_id !== "sub_free" ?
                                setOpenModal(true)
                                :
                                createCheckoutSessionHandler(tier.price_id)
                              : signOut(tier.price_id)
                          }> {localStorage.getItem('userToken') ? 'Checkout' : 'Login'} </span>
                    }
                  </div>
                </div>
              </div>
            ))}


          </div>
        </div>
      </section>
      
      <ModalCG
        openModal={openModal}
        type="succes"
        title="You have an active subscription."
        btnAccept={{
          text: "Accept",
          action: () => openModalHandler(false)
        }}
      />

        <ModalCG
            openModal={openModalFreeTrial}
            type="continue"
            title="Do you want to delete your current plan?"
            btcCancel={{
                    text:"Cancel",
                    action: () => setOpenModalFreeTrial(false)
                }}
            btnAccept={{
                    text: "Continue",
                    action: ()=> deactivateFreePlanHandler(currentUserData.uid, currentUserData.email)
                }}
        />

      <ModalCG
        openModal={openFreeModal}
        type="succes"
        title="You have already used the free trial."
        btnAccept={{
          text: "Accept",
          action: () => openFreeModalHandler(false)
        }}
      />

      <Modal
        open={modalForm}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <UserForm closeModalForm={cleanModalData} closeModalAndUpdate={closeModalAndUpdate} dataToEdit={userInformation} />
      </Modal>

      <Backdrop className="backdrop-app" open={backdrop} >
        <CircularProgress color="inherit" />
      </Backdrop>

    </React.Fragment>
  );
}
