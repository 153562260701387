import React, { useState } from 'react';
// Material UI
import PersonIcon from '@material-ui/icons/Person';
import { auth } from '../../services/firebase';

export  default function MenuUser({landing}) {
    const signOut = () => {
        if (!localStorage.getItem('userData')) {
          window.location.pathname = '/login'
        } else {
          auth.signOut().then(function () {
            localStorage.clear();
            window.location.pathname = '/'
          }).catch(function (error) {
            alert('Algo salio mal')
          });
        }
      }

    const [currentUser, handlerUser] = useState(localStorage.getItem('userData'));
    console.log("-----------currentUser-----------", JSON.parse(currentUser));
    return (
        <nav class="navbar navbar-area navbar-expand-lg nav-absolute white nav-style-01">
            <div class="container nav-container">
            <div class="responsive-mobile-menu">
                <div class="logo-wrapper">
                <a href="index.html" class="logo">
                    <img src="assets/img/logo-cg-icon-w3.png" alt="ClueGarden" />
                </a>
                </div>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#appside_main_menu"
                aria-expanded="false" aria-label="Toggle navigation" onclick="ga('navbar-toggler', 'event', 'Category', 'Action', 'Label', 'Value');">
                <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse" id="appside_main_menu">
                <ul class="navbar-nav">
                <li class="current-menu-item">
                    <a href={`${!landing ? '/' : ''}#`} onclick="ga('send', 'event', 'navbar-home', 'Action', 'Label', 'Value');">Home</a>
                </li>
                <li><a href={`${!landing ? '/' : ''}#what`} onclick="ga('send', 'navbar-what', 'Category', 'Action', 'Label', 'Value');">What</a></li>
                <li><a href={`${!landing ? '/' : ''}#why`} onclick="ga('send', 'navbar-why', 'Category', 'Action', 'Label', 'Value');">Why</a></li>
                <li><a href={`${!landing ? '/' : ''}#how`} onclick="ga('send', 'navbar-how', 'Category', 'Action', 'Label', 'Value');">How</a></li>
                <li><a href={`${!landing ? '/' : ''}#faqs`} onclick="ga('send', 'navbar-faq', 'Category', 'Action', 'Label', 'Value');">FAQs</a></li>
                <li><a href={`${!landing ? '/' : ''}#pricing`} onclick="ga('send', 'navbar-pricing', 'Category', 'Action', 'Label', 'Value');">Pricing</a></li>
                <li><a href={`${!landing ? '/' : ''}#contact`} onclick="ga('send', 'navbar-contact', 'Category', 'Action', 'Label', 'Value');">Contact</a></li>
                { currentUser &&
                    <li>
                        <a hrefd="/edit_user" href="javascript::void(0)" className="cursor--disabled">
                            { JSON.parse(currentUser).firstname ? JSON.parse(currentUser).firstname  : JSON.parse(currentUser).firstName  }
                        </a>
                    </li>
                }
                <li>
                    <a href={`${!landing ? '/' : ''}#`} onClick={signOut} className="cont-icon-menu-user-h-ref" onclick="ga('send', 'navbar-login', 'Category', 'Action', 'Label', 'Value');">
                    <div>
                        / <span> {!localStorage.getItem('userData') ? 'Login' : 'Sign Out'}</span>  <img className="cont-icon-menu-user-png" src="assets/img/icon-profile.png"/>
                    </div>
                    </a>
                </li>
                </ul>
            </div>

            </div>
        </nav>
    )
}