import { global, axios } from '../constants.js';
import { getHeader, auth, activateFreePlan } from './firebase';

// Stripe
const stripe = window.Stripe('pk_live_51HfUmxEV3WNlsDik7mNom4Xo0QgyPbherARzCUPkffMlRpvoXzPlvBCv2XHC6jByAYJEjtHJe94TqCw2q2674io200WtlseqHn');
 
export const verifyCustomerAndRetrieveUser = async  (uid, email) => {
  const headers = await getHeader()
  let url = global.API_GET_VERIFY_CUSTOMER_AND_RETRIEVER_USER.replace('__uid__', uid);
  url = url.replace('__email__', email)
  return axios.get(url, { 'headers': headers })
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.log(error);
    });
}

export const createCheckoutSessionHandler = (priceID, uid) => {
  uid = uid || auth.currentUser.uid;
  console.log(priceID, uid)
  createCheckoutSession(uid, priceID)
    .then((sessionID) => {
      return stripe
        .redirectToCheckout({
          sessionId: sessionID
        })
    })
    .then((handleResult) => {
      console.log({ handleResult })
    })
    .catch((err) => {
      console.log(err);
    })
}

//------------------------> Products  ------------------------------->

export const postCreateProduct = async (data) => {
  const headers = await getHeader()
  let url = global.API_POST_CREATE_PRODUCT;
  return axios.post(url, data, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const updateProduct = async (productID, data) => {
  const headers = await getHeader()
  let url = global.API_PATCH_UPDATE_PRODUCT.replace('__product_id__', productID);
  return axios.patch(url, data, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const handlerProductStatus = async (productID, data) => {
  const headers = await getHeader()
  let url = global.API_PATCH_DISABLE_PRODUCT.replace('__product_id__', productID)
  return axios.patch(url, data, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

//------------------------> Prices  ------------------------------->

export const postCreatePrice = async (data) => {
  const headers = await getHeader()
  let url = global.API_POST_CREATE_PRICE;
  return axios.post(url, data, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const updatePrice = async (priceID, data) => {
  const headers = await getHeader()
  let url = global.API_PATCH_UPDATE_PRICE.replace('__price_id__', priceID);
  return axios.patch(url, data, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const handlerPriceStatus = async (priceID, data) => {
  const headers = await getHeader()
  let url = global.API_PATCH_DISABLE_PRICE.replace('__price_id__', priceID)
  return axios.patch(url, data, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const createCheckoutSession = async (uid, priceID) => {
  const headers = await getHeader()
  let url = global.API_POST_CREATE_CHECKOUT_SESSION.replace('__uid__', uid);
  return axios.post(url, { price_id: priceID }, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const createBillingPortalSession = async (uid) => {
  const headers = await getHeader()
  let url = global.API_POST_CREATE_BILLING_PORTAL_SESSION.replace('__uid__', uid);
  return axios.post(url, {}, { 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}

export const getCheckoutSession = async (sessionID) => {
  const headers = await getHeader()
  let url = global.API_GET_CHECKOUT_SESSION
  return axios.get(url, { params: { session_id: sessionID }, 'headers': headers })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error);
    });
}